<template>
	<div>
		<ContentHeader title="Stok Produk" url="/stok-produk" subTitle="Stok Percetakan" />
		<LoadingScreen v-if="loading_screen" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row d-flex align-items-center">
		            			<h3 class="card-title col-6">Table Percetakan</h3>
		            		</div>	
		            		<div class="card-body">
		            			<div class="row align-items-center">
		            				<div class="col-lg-2">
		            					<span>Jumlah Baris</span>
		            					<select class="custom-select form-control" v-model="page" @change="getDataResult">
			            					<option value="10">10</option>
			            					<option value="15">15</option>
			            					<option value="20">20</option>
			            					<option value="25">25</option>
			            					<option value="30">30</option>
			            					<option value="40">40</option>
			            					<option value="45">45</option>
			            					<option value="50">50</option>
			            				</select>
		            				</div>
		            				<div class="col-lg-4">
		            					<label>Lokasi</label>
	    								<multiselect 
								          v-model="filter_lokasi"
								          deselect-label="Can't remove this value"
								          track-by="nama"
								          label="nama"
								          placeholder=""
								          :loading="loading_lokasi"
								          :options="lokasi"
								          :allow-empty="false"
								          @close="getDataResult"
								        >
								        </multiselect>
		            				</div>
		            				<div class="col-lg-2"></div>
		            				<div class="col-lg-4">
		            					<span>Cari Data</span>
		            					<input type="text" class="form-control" v-model="kata_kunci" @keyup="getDataResult">
		            				</div>
		            			</div>
								<div class="row align-items-center">
									<div class="col-lg-2">
										<span>Tipe Obat</span>
										<select class="custom-select form-control" v-model="tipe_obat" @focus="jenisObat ? '' : getJenisObat()" @change="getDataResult">
											<option value="emptyParameter">Semua</option>
											<option v-for="(row, index) in jenisObat" :key="index" :value="row.nama">{{ row.nama }}</option>
										</select>
									</div>
								</div>
		            			<table class="table table-bordered table-hover mt-4">
								  <thead>
								    <tr>
								      <th scope="col" class="text-sm">NO</th>	
								      <th scope="col" class="text-sm">KODE</th>
								      <th scope="col" class="text-sm">NAMA</th>
								      <th scope="col" class="text-sm">JUMLAH STOK</th>
								      <th scope="col" class="text-sm">SATUAN</th>
								      <th scope="col" class="text-sm" v-if="cabang.jenis == 'pusat'">HARGA JUAL (Pusat)</th>
								      <th scope="col" class="text-sm">HARGA JUAL (Cabang)</th>
								      <th scope="col" class="text-sm">LOKASI</th>
                					  <th scope="col" class="text-sm" v-if="level_jabatan != 'staff'">ACTION</th>
								    </tr>
								  </thead>
								  <tbody>
								    <tr v-for="(row, index) in data_result" :key="row.id">
								      <td class="text-sm" scope="row">{{ index+1 }}</td>
								      <td class="text-sm" scope="row">{{ row.produk.kode }}</td>
								      <td class="text-sm" scope="row">{{ row.produk.nama }}</td>
								      <td class="text-sm" scope="row">{{ row.stok_akhir }}</td>
								      <td class="text-sm" scope="row">{{ row.produk.satuan }}</td>
								      <td class="text-sm" scope="row" v-if="cabang.jenis == 'pusat'">{{ format_nominal(row.produk.harga_jual) }}</td>
								      <td class="text-sm" scope="row">{{ row.harga_jual ? format_nominal(row.harga_jual) : 'Mengikuti Pusat' }}</td>
								      <td class="text-sm" scope="row">{{ row.lokasi_id ? row.lokasi.nama : '-' }}</td>
								      <td class="text-sm" scope="row" @click="getDetailStok(row.id)" v-if="level_jabatan != 'staff'">
					                    <button type="button" class="btn btn-primary" data-toggle="modal" data-target=".editStok">
					                      Edit
					                    </button>
					                  </td>
								    </tr>
								  </tbody>
								</table>
								<p class="text-center mt-4" v-if="memuat_data">Memuat Data</p>
								<p class="text-center mt-4" v-if="cek_data">Data Masih Kosong</p>
		            		</div>
		            		<div class="card-footer">
								<nav aria-label="...">
								  <ul class="pagination">
								    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.from}" @click="paginateData(pagination.prev_page_url)">
								      <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
								    </li>
								    <li class="page-item" :class="{ active: pagination.current_page == row.label }" @click="paginateData(row.url)" v-for="(row, index) in links" :key="index"><a class="page-link" href="#">{{ row.label }}</a></li>
								    <li class="page-item" :class="{ disabled: pagination.current_page == pagination.last_page}" @click="paginateData(pagination.next_page_url)">
								      <a class="page-link" href="#">Next</a>
								    </li>
								  </ul>
								</nav>
		            		</div>

		            		<!-- Large modal -->
						    <div class="modal fade editStok" ref="myModal">
					 	      <div class="modal-dialog modal-lg">
						        <div class="modal-content">
						          <div class="modal-header">
						            <h5 class="modal-title">Edit Stok Obat</h5>
						            <button type="button" class="close">
						              <span>&times;</span>
						            </button>
						            </div>
						          <div class="modal-body">
						          	<form class="row">
						          		<div class="col-lg-1"></div>
						          		<div class="col-lg-10">
						          			<div class="form-group">
											    <label for="">Kode</label>
											    <input type="" class="form-control" id="" disabled="" v-model="detail_stok.produk.kode">
											</div>
											<div class="form-group">
											    <label for="">Nama</label>
											    <input type="" class="form-control" id="" disabled="" v-model="detail_stok.produk.nama">
											</div>
											<div class="form-group">
											    <label for="">Stok</label>
											    <input type="" class="form-control" id="" disabled="" v-model="detail_stok.stok_akhir">
											</div>
											<div class="form-group">
											    <label for="">Harga Jual (Pusat - Default)</label>
											    <input type="" class="form-control" id="" disabled="" v-model="detail_stok.produk.harga_jual">
											</div>
											<div class="form-group">
											    <label for="">Harga Jual</label>
											    <input type="" class="form-control" id="" v-model="detail_stok.harga_jual">
											</div>
											<div class="form-group" v-if="detail_stok.lokasi_id">
											    <label for="">Lokasi</label>
											    <select class="custom-select form-control" v-model="detail_stok.lokasi_id">
											    	<option v-for="(row, index) in lokasi" :key="index" :value="row.id">{{ row.nama }}</option>
											    </select>
											</div>
											<div class="form-group" v-else>
			        							<label class="typo__label">Lokasi</label>
			    								<multiselect 
										          v-model="lokasi_select_value"
										          deselect-label="Can't remove this value"
										          track-by="nama"
										          label="nama"
										          placeholder=""
										          :loading="loading_lokasi"
										          :options="lokasi"
										          :allow-empty="false"
										        >
										        </multiselect>
								           	</div>
						          		</div>
						          		<div class="col-lg-1"></div>
						          	</form>
						          </div>
						          <div class="modal-footer">
						            <button type="button" class="btn btn-primary" data-dismiss="modal" @click="closeModal">Simpan</button>
						            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
						          </div>
						        </div>
						      </div>
						    </div>

						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'

	import { computed, ref, onMounted } from 'vue'
	import store from '@/store'
	import axios from 'axios'
	import format_nominal from '@/format_nominal'
	import Swal from 'sweetalert2'
	import LoadingScreen from '@/components/LoadingScreen.vue'
	import Multiselect from '@suadelabs/vue3-multiselect'

	const data_result = ref([])
	const cek_data = ref(false)
	const user = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])
	const level_jabatan = computed(() => store.getters['auth/level_jabatan'])
	const memuat_data = ref(true)
	const page = ref(10)
	const tipe_obat = ref('emptyParameter')
	const links = ref([])
	const pagination = ref([])
	const kata_kunci = ref('')
	const loading_screen = ref(false)
	const filter_lokasi = ref('')

	const getDataResult = async () => {
		data_result.value = []
		memuat_data.value = true
		cek_data.value = false

		let parameter = ''
		if (kata_kunci.value != '') {
			parameter = kata_kunci.value
		} else {
			parameter = 'emptyParameter'
		}

		let lokasi = ''
		if (filter_lokasi.value == '') {
			lokasi = 'semua'
		} else {
			lokasi = filter_lokasi.value.id
		}

		let { data } = await axios.get(`api/stok_produk/getStokProdukWhereJenisPaginate/${user.value.cabang_id}/percetakan/${page.value}/${parameter}/${lokasi}/${tipe_obat.value ? tipe_obat.value : 'emptyParameter'}`)

		if (data != 'kosong') {
			memuat_data.value = false
			cek_data.value = false
			data_result.value = data.data
			links.value = data.links
			links.value.splice(0, 1)
			links.value.splice(links.value.length-1, 1)
			pagination.value = data
		} else {
			cek_data.value = true
			memuat_data.value = false
			data_result.value = []
		}
	}

	const detail_stok = ref({
		'produk' : {
			'kode' : '',
			'nama' : '',
			'harga_jual' : ''
		},
		'harga_jual' : ''
	})

	const getDetailStok = async (id) => {
		let { data } = await axios.get(`api/stok_produk/getDetailStok/${id}`);

		if (data == 'kosong') {
			detail_stok.value = {
				'produk' : {
					'kode' : '',
					'nama' : '',
					'harga_jual' : ''
				},
				'harga_jual' : '',
				'lokasi_id' : ''
			}
		} else {
			detail_stok.value = data.data
		}
	}

	const closeModal = () => {
		loading_screen.value = true
		if (detail_stok.value.lokasi_id == null && lokasi_select_value.value != '') {
			detail_stok.value.lokasi_id = lokasi_select_value.value.id
		}
		axios.put(`api/stok_produk/updateHargaJUalDanLokasi/${detail_stok.value.id}`, detail_stok.value)
		.then((response) => {
			Swal.fire({
				title: 'Berhasil!',
				text: 'Berhasil memperbaharui Data',
				icon: 'success',
				showConfirmButton: false,
				timer: 1500
			})
			lokasi_select_value.value = ''
			loading_screen.value = false
			console.log(response.data)
			getDataResult()
		})
		.catch((error) => {
			Swal.fire({
				title: 'Gagal!',
				text: 'Gagal memperbaharui data',
				icon: 'error',
				showConfirmButton: false,
				timer: 1500
			})
			lokasi_select_value.value = ''
			loading_screen.value = false
			console.log(error)
		})

		detail_stok.value = {
			'produk' : {
				'kode' : '',
				'nama' : '',
				'harga_jual' : ''
			},
			'harga_jual' : ''	
		}
	}

	// Lokasi
	const lokasi_select_value = ref('')
	const lokasi = ref([])
	const loading_lokasi = ref(false)

	const getLokasi = async () => {
		loading_lokasi.value = true
		let { data } = await axios.get(`api/lokasi/get/${user.value.cabang_id}`)

		if (data == 'kosong') {
			lokasi.value = []
			loading_lokasi.value = false
		} else {
			lokasi.value = data.data
			lokasi.value.unshift({
				'id' : 'tanpa_lokasi',
				'nama' : 'Tanpa Lokasi'	
			})
			lokasi.value.unshift({
				'id' : 'semua',
				'nama' : 'Semua'
			})
			loading_lokasi.value = false
		}
	}
	// end lokasi

	const paginateData = async (url) => {

	if (url != null) {
			data_result.value = []
			memuat_data.value = true

			let link_url = new URL(url)
			link_url = `${link_url.pathname}${link_url.search}`
			
			let { data } = await axios.get(`${link_url}`)

			
			if (data != 'kosong') {
				memuat_data.value = false
				data_result.value = data.data	
				links.value = data.links
				links.value.splice(0, 1)
				links.value.splice(links.value.length-1, 1)
				pagination.value = data
			}
		}
	}

	const jenisObat = ref(null)
	const getJenisObat = async () => {
		await axios.get('api/detail_produk/getWhereJenis/jenis')
		.then((res) => {
			jenisObat.value = res.data
			console.log(res)
		})
	}

	onMounted(() => {
		getDataResult()
		getLokasi()
	})
</script>